"use client";

import { useId } from "react";

import { FieldSet, FieldSetProps } from "../field-set/field-set";
import { useFieldValue } from "../use-field-value";
import { CheckboxGroupContext } from "./checkbox-group-context";

type Props = {
  /** Controlled value */
  value?: string[];
  /** Init value */
  defaultValue?: string[];
  name: string;
  onChange?: (value: string[]) => void;
};
export type CheckboxGroupProps = Props &
  Omit<FieldSetProps, "errorId" | "onChange" | "defaultValue" | "hideLabel">;

export function CheckboxGroup({
  name,
  children,
  disabled,
  onChange: onChangeFromProps,
  value,
  defaultValue,
  ref,
  error,
  ...rest
}: CheckboxGroupProps) {
  const { value: selectedValues, onChange } = useFieldValue({
    defaultValue,
    multiselect: true,
    value,
    onChange: onChangeFromProps
  });
  const errorId = useId();

  function toggleValue(value: string) {
    if (selectedValues.includes(value)) {
      onChange(selectedValues.filter((val) => val !== value));
    } else {
      onChange([...selectedValues, value]);
    }
  }

  return (
    <FieldSet ref={ref} errorId={errorId} error={error} {...rest}>
      <CheckboxGroupContext.Provider
        value={{
          name,
          error,
          disabled: !!disabled,
          selectedValues,
          toggleValue
        }}
      >
        {children}
      </CheckboxGroupContext.Provider>
    </FieldSet>
  );
}
